// @ts-nocheck
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

export default class ScimAPI extends BaseAPI {
  @recoverFromNotFound()
  async findSettings(organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const endpoint = `${this.host.tcUrl}/v1/user-service/SCIMConfiguration`;
    const res = await this.httpClient.get(endpoint, { headers });
    return res.data;
  }

  @recoverFromNotFound()
  async findBearerToken(organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const endpoint = `${this.host.tcUrl}/v1/user-service/BearerToken`;
    const res = await this.httpClient.get(endpoint, { headers });
    return res.data;
  }

  @recoverFromNotFound()
  async setupScim({
    suffix,
    prefix,
    organizationId,
  }: {
    suffix?: string;
    prefix?: string;
    organizationId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const endpoint = `${this.host.tcUrl}/v1/user-service/SetupSCIM`;
    const res = await this.httpClient.post(endpoint, {
      data: {},
      headers,
    });
    return res.data;
  }

  async findAvailableSCIMFields(organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const endpoint = `${this.host.tcUrl}/v1/user-service/AvailableSCIMFields`;
    const res = await this.httpClient.get(endpoint, { headers });
    return res.data;
  }
}
